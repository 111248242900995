function Box_3(){
    return (
        <div className='box-3'>
            <b>
                Dr. William Jervis
                <br/>
                1844 San Miguel Dr #109
                <br/>
                Walnut Creek, CA 94596
                <br/>
                <span className='colored_field'>925-937-7100</span>
            </b>
        </div>
    )
}

export default Box_3;