import './Footer.css'

function Footer(){
    return (
        <div className='footer'>
                <a href='/home'>Home</a> | <span/>
                <a href='/about_doctor'>About Dr. Jervis</a> | <span/>
                <a href='/consultations'>Consultations</a> | <span/>
                <a href='/procedures'>Procedures</a> | <span/>
                <a href='/surgery_center'>Surgical Center</a> | <span/>
                {/*<a href=''>Surgical Procedures</a> | <span/>*/}
                <a href='/aaaasf'>AAAASF Surgical Facility</a> | <span/>
                <a href='/faqs'>FAQS</a> | <span/>
                <a href='/links'>Links</a> | <span/>
                <a href='/testimonials'>Testimonials</a>
                <br/>
                <a href='/botox_and_fillers'>Botox & Fillers</a> | <span/>
                <a href='/breast/augmentation'>Breast Augmentation</a> | <span/>
                {/*<a href='/breast/enlargement'>Breast Enlargement</a> | <span/>*/}
                {/*<a href='/breast/lift'>Breast Lift</a> | <span/>*/}
                <a href='/breast/reduction'>Breast Reduction</a> <span/>
                {/*<a href=''>Mommy Makeover</a> | <span/>*/}
                {/*<a href=''>Liposuction</a> | <span/>*/}
                {/*<a href=''>TUMMY TUCK</a> | <span/>*/}
                {/*<a href=''>PHOTO GALLERY</a> | <span/>*/}
                {/*<a href=''>Financing</a>*/}
                <br/>

                {/*<a href=''>Patient Forms</a> | <span/>*/}
                {/*<a href=''>Directions</a> | <span/>*/}
                {/*<a href=''>What’s New</a> | <span/>*/}
                {/*<a href=''>Site Map</a> | <span/>*/}
                {/*<a href=''>Ear Lobe Repair</a> | <span/>*/}
                <a href='/face'>Face Procedures</a> | <span/>
                {/*<a href=''>Facial Implants</a> | <span/>*/}
                {/*<a href=''>Face Lift</a> | <span/>*/}
                {/*<a href=''>Fat Transfer</a>*/}
                {/*<br/>*/}
                {/*<a href=''>Gynecomastia</a> | <span/>*/}
                <a href='/face/lip_augmentation'>Lip Augmentation</a> | <span/>
                {/*<a href=''>Ears-Otoplasty</a> | <span/>*/}
                <a href='/face/eyelid_lift'>Eyes-Blepharoplasty</a> <span/>
                <br/>
                <a href='/contact_doctor'>Contact Dr. Jervis</a> | <span/>
                <a href='/'>Home</a> <span/>
                {/*<a href=''>AVTA Facelift Technic</a>*/}
                <br/>
                <br/>
                Copyright 2010 William Jervis, MD: Breast Augmentation California. All Rights Reserved
        </div>
    )
}

// Home		About Dr. Jervis		Consultations		Surgical Center		Surgical Procedures		AAAASF Surgical Facility		FAQS		Links		Testimonials
//
// Botox & Fillers		Breast Augmentation		Breast Enlargement		Breast Lift		Breast Reduction		Mommy Makeover		Liposuction		TUMMY TUCK		PHOTO GALLERY		Financing
//
// Contact Dr. Jervis		Patient Forms		Directions		What’s New		Site Map		Ear Lobe Repair		Face Procedures		Facial Implants		Face Lift		Fat Transfer
//
// Gynecomastia		Lip Augmentation		Ears-Otoplasty		Eyes-Blepharoplasty		AVTA Facelift Technic
//
// Copyright 2010 William Jervis, MD: Breast Augmentation California. All Rights Reserved

export default Footer;