import header from '../../images/header.png'
import './Header.css'
function Header(){
    return(
        <div id='header'>
            <img src={header}/>
        </div>
    )
}

export default Header;